import React, {createRef} from "react";
import {
  Button,
  Row,
  Col,
  ModalBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { withTranslation } from "react-i18next";
import TimeHelper from "../../helpers/TimeHelper";
import FREETABLE from "../../assets/img/pages/table_empty.png";
import BOOKEDTABLE from "../../assets/img/pages/table_booked.png";
import QRCode from "qrcode.react";
import { Link, X } from "react-feather";
import { saveAs } from "file-saver";
import CONSTANTS from "../../constants";
import i18n from "i18next";
import {toJpeg} from "html-to-image";

class ListSessionTablesStatus extends React.Component {
  qrRef = createRef();
  state = {
    inCart: [],
    inWishlist: [],
    view: "grid-view",
    qrModalOpen: false,
    qrImageUrl: "",
    qrImageName: "",
    redirectURL: "",
    activeTab: "1",
  };

  componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }
  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  getOrderFromDisplayName = (orderFrom) => {
    switch (orderFrom) {
      case "QR Scan":
        return "QuickServe";
      case "Eater App":
        return "ServTable";
      case "Waiter App":
        return "ServWait";
      default:
        return orderFrom;
    }
  };

  getSessionTime = (time_diff) => {
    let cDate = new Date();
    let sDate = new Date(time_diff);
    const diffTime = Math.abs(cDate - sDate);
    let diffMinutes = Math.ceil(diffTime / (1000 * 60));

    let result;
    if (diffMinutes > 60) {
      const diffHours = Math.floor(diffMinutes / 60);
      result = `${diffHours} Hrs`;
    } else {
      result = `${diffMinutes} Minutes`;
    }

    return result;
  };

  formatAmount = (amount) => {
    if (amount >= 1000) {
      if (amount >= 1000000 && amount < 1000000000) {
        return (amount / 1000000).toFixed(1) + "M";
      } else if (amount >= 1000000000) {
        return (amount / 1000000000).toFixed(1) + "B";
      } else {
        return (amount / 1000).toFixed(1) + "K";
      }
    } else {
      return amount.toFixed(2);
    }
  };

  openQrModal = (qrCodeUrl, qrImageName, url, image_name) => {
    this.setState({
      qrModalOpen: true,
      qrImageUrl: qrCodeUrl,
      qrImageName: qrImageName,
      redirectURL: url,
      imageDownload_URL: image_name,
    });
  };

  closeQrModal = () => {
    this.setState({
      qrModalOpen: false,
      qrImageUrl: "",
      qrImageName: "",
      redirectURL: "",
      imageDownload_URL: "",
    });
  };

  redirectToURL = (url) => {
    this.closeQrModal();
    window.open(url, "_blank");
  };

  goToOrderSession = (sessionId) => {
    window.open(`${CONSTANTS.BASE_URL}sessions/${sessionId}`, "_self");
  };

  handleDownloadImage = async () => {
    let url = CONSTANTS.RESTAURANT_URL + this.state.imageDownload_URL;

    let qr = <div ref={this.qrRef}>
      <img src={url} alt={"qrcode"} />
    </div>
    if (this.qrRef.current) {
      toJpeg(this.qrRef.current)
          .then((dataUrl) => {
            saveAs(dataUrl, `${this.state.qrImageName}.jpeg`);
          })
          .catch((error) => {
            console.error('Error saving QR code image', error);
          });
    }
  }

  render() {
    const { tables, t } = this.props;

    const customStyles = {
      headCells: {
        style: {
          fontWeight: "bold",
        },
      },
    };

    const buttonStyle = {
      flex: 1,
      margin: "5px",
      display: "flex",
      alignItems: "center",
    };

    const tableData = tables?.data.map((table, index) => {
      const sessionTime = new Date(
          table?.LocationSessions[0]?.session_date +
          " " +
          table?.LocationSessions[0]?.start_time
      );
      const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
      const tableAvailable = table?.is_table_available;

      let orderAmount = 0;
      for (let i = 0; i < tables.sessionAmounts.length; i++) {
        let flag = false;
        if (table && table.LocationSessions && table.LocationSessions.length > 0 && table.LocationSessions[0].Orders && table.LocationSessions[0].Orders.length > 0) {
          flag = true;
          if (table.LocationSessions[0].session_id === tables.sessionAmounts[i]["Order.LocationSession.session_id"]) {
            orderAmount = this.props.location?.currency + " " + this.formatAmount(tables.sessionAmounts[i].total_order_sum);
          }
        }
        if (!flag) {
          orderAmount = 0;
        }
      }

      return {
        id: index,
        name: table.table_name,
        status: tableAvailable ? "Free" : "Occupied",
        time: tableAvailable ? "00" : this.getSessionTime(sessionTime),
        amount: tableAvailable ? 0 : orderAmount,
        floorName: table?.Floor?.floor_name,
        sessionStatus:
            table?.LocationSessions[0]?.session_status === "started"
                ? this.getOrderFromDisplayName(
                    table?.LocationSessions[0]?.order_from
                )
                : null,
        sessionTime: tableAvailable ? null : sessionTimeCorrect,
        image: tableAvailable ? FREETABLE : BOOKEDTABLE,
        sessionId: table?.LocationSessions[0]?.session_id,
        qrCodeData: table.qrcodedata,
        sessions: table?.LocationSessions[0]?.session_id,
        sessionsStatus: table?.LocationSessions[0]?.session_status,
        image_name: table.image_name ? table.image_name : "",
      };
    });

    const roomData = tables.rooms?.map((room, index) => {
      const sessionTime = new Date(
          room?.LocationSessions[0]?.session_date +
          " " +
          room?.LocationSessions[0]?.start_time
      );
      const sessionTimeCorrect = TimeHelper.toFormattedTime(sessionTime);
      const roomAvailable = room?.room_available_restaurant;
      const orderAmount =
          room?.LocationSessions[0]?.Orders.length > 0
              ? this.props.location?.currency +
              " " +
              this.formatAmount(
                  room?.LocationSessions[0]?.Orders[0].session_order_amount
              )
              : 0;

      return {
        id: index,
        name: room.room_name,
        status: roomAvailable ? "Free" : "Occupied",
        time: roomAvailable ? "00" : this.getSessionTime(sessionTime),
        amount: roomAvailable ? 0 : orderAmount,
        hotelName: room?.hotel_name,
        sessionStatus:
            room?.LocationSessions[0]?.session_status === "started"
                ? this.getOrderFromDisplayName(
                    room?.LocationSessions[0]?.order_from
                )
                : null,
        sessionTime: roomAvailable ? null : sessionTimeCorrect,
        image: roomAvailable ? FREETABLE : BOOKEDTABLE,
        sessionId: room?.LocationSessions[0]?.session_id,
        qrCodeData: room.qrcodedata,
        sessions: room?.LocationSessions[0]?.session_id,
        sessionsStatus: room?.LocationSessions[0]?.session_status,
      };
    });

    const tableColumns = [
      {
        name: t("Table Name"),
        selector: (row) => row?.name,
        sortable: true,
      },
      {
        name: t("Floor Name"),
        selector: (row) => row?.floorName,
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row?.amount,
        sortable: true,
        cell: (row) => <span>{row?.amount}</span>,
      },
      {
        name: "Time Elapsed",
        selector: (row) => row?.time,
        sortable: true,
        cell: (row) => <span>{row?.time}</span>,
      },
      {
        name: t("Session Time"),
        selector: (row) => row?.sessionTime,
        sortable: true,
        cell: (row) => row.sessionTime && <span>{row?.sessionTime}</span>,
      },
      {
        name: "Order From",
        selector: (row) => row?.sessionStatus,
        sortable: true,
      },
      {
        width: "200px",
        name: "Session Id",
        selector: (row) => row?.sessions,
        sortable: true,
        cell: (row) => {
          if (row?.sessionsStatus === "started") {
            return (
                <button
                    onClick={() => this.goToOrderSession(row?.sessions)}
                    className="btn  btn-link"
                >
                  <span> {row?.sessions}</span>
                </button>
            );
          } else {
            return null;
          }
        },
      },
      {
        name: t("QR Code"),
        selector: (row) => row?.qrCodeData,
        sortable: true,
        cell: (row) =>
            row?.qrCodeData ? (
                <Button
                    outline
                    className="btn-icon"
                    color="primary"
                    onClick={() =>
                        this.openQrModal(row?.qrCodeData, row?.name, row?.qrCodeData, row?.image_name)
                    }
                >
                  <QRCode value={row?.qrCodeData} size={35}  renderAs={"svg"}/>
                </Button>
            ) : null,
      },
      {
        name: t("Action"),
        width: "200px",
        cell: (row) =>
            row?.status === "Occupied" ? (
                <Button
                    onClick={() => this.props.closeLocationSession(row?.sessionId)}
                    type="submit"
                    className="cart btn  btn-warning"
                >
                  {t("close-session")}
                </Button>
            ) : (
                <Button type="button" className="cart btn  btn-success" disabled>
                  {t("Free")}
                </Button>
            ),
      },
    ];

    const roomColumns = [
      {
        name: t("Room Name"),
        selector: (row) => row?.name,
        sortable: true,
      },
      {
        name: t("Hotel Name"),
        selector: (row) => row?.hotelName,
        sortable: true,
      },
      {
        name: t("Amount"),
        selector: (row) => row?.amount,
        sortable: true,
        cell: (row) => <span>{row?.amount}</span>,
      },
      {
        name: "Time Elapsed",
        selector: (row) => row?.time,
        sortable: true,
        cell: (row) => <span>{row?.time}</span>,
      },
      {
        name: t("Session Time"),
        selector: (row) => row?.sessionTime,
        sortable: true,
        cell: (row) => row.sessionTime && <span>{row?.sessionTime}</span>,
      },
      {
        name: "Order From",
        selector: (row) => row?.sessionStatus,
        sortable: true,
      },
      {
        width: "200px",
        name: "Session Id",
        selector: (row) => row?.sessions,
        sortable: true,
        cell: (row) => {
          if (row?.sessionsStatus === "started") {
            return (
                <button
                    onClick={() => this.goToOrderSession(row?.sessions)}
                    className="btn  btn-link"
                >
                  <span> {row?.sessions}</span>
                </button>
            );
          } else {
            return null;
          }
        },
      },
      // {
      //   name: t("QR Code"),
      //   selector: (row) => row?.qrCodeData,
      //   sortable: true,
      //   cell: (row) =>
      //     row?.qrCodeData ? (
      //       <Button
      //         outline
      //         className="btn-icon"
      //         color="primary"
      //         onClick={() =>
      //           this.openQrModal(row?.qrCodeData, row?.name, row?.qrCodeData)
      //         }
      //       >
      //         <QRCode value={row?.qrCodeData} size={35} />
      //       </Button>
      //     ) : null,
      // },
      {
        name: t("Action"),
        width: "200px",
        cell: (row) =>
            row?.status === "Occupied" ? (
                <Button
                    onClick={() => this.props.closeLocationSession(row?.sessionId)}
                    type="submit"
                    className="cart btn  btn-warning"
                >
                  {t("close-session")}
                </Button>
            ) : (
                <Button type="button" className="cart btn  btn-success" disabled>
                  {t("Free")}
                </Button>
            ),
      },
    ];

    const tableDataFinal = {
      columns: tableColumns,
      data: tableData,
      print: false,
      export: false,
    };

    const roomDataFinal = {
      columns: roomColumns,
      data: roomData,
      print: false,
      export: false,
    };

    return (
        <>
          <div className="col-12">
            <Nav tabs>
              <NavItem>
                <NavLink
                    className={this.state.activeTab === "1" ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                >
                  Tables
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className={this.state.activeTab === "2" ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("2");
                    }}
                >
                  Rooms
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12" lg="12">
                    <DataTableExtensions {...tableDataFinal}>
                      <DataTable
                          noHeader
                          pagination
                          paginationRowsPerPageOptions={[25, 50, 100]}
                          paginationPerPage={50}
                          columns={tableColumns}
                          data={tableData}
                          customStyles={customStyles}
                      />
                    </DataTableExtensions>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12" lg="12">
                    <DataTableExtensions {...roomDataFinal}>
                      <DataTable
                          noHeader
                          pagination
                          paginationRowsPerPageOptions={[25, 50, 100]}
                          paginationPerPage={50}
                          columns={roomColumns}
                          data={roomData}
                          customStyles={customStyles}
                      />
                    </DataTableExtensions>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>

          <Modal
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "500px",
              }}
              isOpen={this.state.qrModalOpen}
              toggle={this.closeQrModal}
          >
            <ModalBody>
              <div className="display-flex full-width flex-space-between">
                <Button.Ripple
                    style={{ width: 20, height: 20 }}
                    onClick={this.closeQrModal}
                    className="m-0 p-0"
                    color="danger"
                >
                  <X size={14} />
                </Button.Ripple>
              </div>
              <div style={{ textAlign: "center", padding: "50px" }}>
                <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                >
                  <Button
                      style={buttonStyle}
                      className="btn-icon"
                      color="primary"
                      onClick={() => this.redirectToURL(this.state.redirectURL)}
                  >
                    <Link size={16} style={{ marginRight: "5px" }} />{" "}
                    {t("qr-url")}
                  </Button>
                  <h4 style={{ marginBottom: "-3px" }}>
                    {this.state.qrImageName}
                  </h4>
                  <div
                      ref={this.qrRef}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                      }}
                  >
                    <QRCode value={this.state.qrImageUrl} size={400}  renderAs={"svg"}/>
                  </div>
                  <Button
                      style={buttonStyle}
                      className="btn-icon"
                      color="primary"
                      onClick={() => this.handleDownloadImage()}
                  >
                    {t("download")}
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
    );
  }
}

export default withTranslation("global")(ListSessionTablesStatus);
