import {
  faArrowLeft,
  faCartPlus,
  faCircleNotch,
  faEye
} from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMenuGroupedByCategory } from '../../../redux/actions/categories';
import { getLocation } from '../../../redux/actions/locations';
import Lens from './lens.png';
import './MenuOptions.css';

import SpinnerComponent from '../../@vuexy/spinner/Fallback-spinner';
import LocationSelectHeader from '../../shared/LocationSelectHeader';
import SelectLocation from '../../shared/SelectLocation';

import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Card, CardHeader, Tooltip } from 'reactstrap';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import CONSTANTS from '../../../constants';
import { getAllDishTags } from '../../../redux/actions/menus';
import BrandingService from '../../../services/BrandingService';
import LocationService from '../../../services/LocationService';

let imageContainer = {
  height: 200,
  // width: 400,
  borderRadius: 1,
  overflow: 'hidden',
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
};

class MenuMobilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      location: null,
      loading: true,
      branding: null,
      payToolTipOpen: false,
      callWaiterToolTipOpen: false,
      cartToolTipOpen: false,
      goBackToolTipOpen: false,
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem('lang');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
    console.log('PROPS: ', this.props);
    const location = LocationService.getLocation();
    if (location) {
      await this.refreshData(location.loc_id);

      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
    const { category_data } = this.props.location.state;
    console.log('CATEGORY_DATA', category_data);
  }

  async refreshData(loc_id, initialize = true, fetchMenu = true) {
    this.setState({ loading: initialize, refreshing: true });
    let locationUpdated = false;
    let branding = null;
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      branding = await BrandingService.getBranding(loc_id);
      await this.props.getLocation(loc_id);
      await this.props.getAllDishTags(loc_id);
      await this.props.getMenuGroupedByCategory(loc_id);

      locationUpdated = true;
    }

    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(this.props.location)),
      refreshing: false,
      isOutlet: this.props.location.is_outlet,
      branding: branding,
    });
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.menu) !== JSON.stringify(this.props.menu) &&
      this.state.location &&
      !this.state.refreshing
    ) {
      this.refreshData(this.state.location.loc_id, false, false);
    }
  }

  togglePayTooltip = () => {
    this.setState({
      payToolTipOpen: !this.state.payToolTipOpen,
    });
  };

  toggleCallWaiterTooltip = () => {
    this.setState({
      callWaiterToolTipOpen: !this.state.callWaiterToolTipOpen,
    });
  };
  toggleCartToolTip = () => {
    this.setState({
      cartToolTipOpen: !this.state.cartToolTipOpen,
    });
  };
  toggleGoBackTooltip = () => {
    this.setState({
      goBackToolTipOpen: !this.state.goBackToolTipOpen,
    });
  };
  render() {
    const { t } = this.props;
    const {
      props: { dishtags, menuData },
    } = this;

    const categoryItem = this.props?.history?.location?.state?.category_data;
    console.log('CATEGORY ITEMS', categoryItem);
    console.log('MENU', menuData);
    const {
      props: { location },
    } = this;

    const {
      state: { selectLocation, loading, branding },
    } = this;

    if (loading) return <SpinnerComponent />;
    const locationName = location ? location.name : '';
    if (selectLocation)
      return (
        <SelectLocation
          onBack={this.toggleLocation.bind(this)}
          onSelect={this.onSelect.bind(this)}
        />
      );

    return (
      <div className="location">
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="d-flex">
            <LocationSelectHeader
              text={locationName}
              onIconClick={this.toggleLocation.bind(this)}
            />
          </div>
        </div>
        <div className="display-flex flex-row justify-content-between mb-1">
          <div className="container">
            <div>
              <div
                style={{
                  background: 'black',
                  borderRadius: '30px',
                  padding: '3.5rem',
                  display: 'flex',
                  margin: 'auto',
                }}
                // className="col-9"
                className="col-lg-8 col-12"
              // className="col-12"
              >
                <button
                  style={{
                    background: 'black',
                    borderRadius: '50%',
                    width: '43px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 1,
                    top: '5px',
                    left: '48%',
                  }}
                >
                  <img
                    src={Lens}
                    alt="Lens Icon"
                    style={{ width: '100%', height: '100%' }}
                  />
                </button>

                <Card
                  className="p-1 col-12"
                  // style={{ backgroundColor: '#F2F2F2' }}
                  style={{
                    backgroundColor: '#F2F2F2',
                    maxHeight: '90vh',
                    overflow: 'auto',
                  }}
                >
                  <CardHeader
                    className=" p-0 p-2 w-100"
                    style={{
                      backgroundColor: `${branding?.basecolor}`,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h4
                      className="mb-0"
                      style={{
                        color: `${branding?.h2_text_color}`,
                        textAlign: 'left',
                        fontWeight: 'bold',
                        position: 'relative',
                      }}
                    >
                      <span style={{ verticalAlign: 'middle' }}>
                        <FontAwesomeIcon
                          id="arrowLeftIcon"
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px',
                            verticalAlign: 'middle',
                            color: 'white',
                            padding: '5px',
                            cursor: 'pointer',
                          }}
                          icon={faArrowLeft}
                          className="mr-1"
                          onClick={() =>
                            this.props.history.push('/menu/mobile-preview')
                          }
                        />
                        {categoryItem?.name}
                      </span>

                      <Tooltip
                        target="arrowLeftIcon"
                        isOpen={this.state.goBackToolTipOpen}
                        toggle={this.toggleGoBackTooltip}
                      >
                        Go back to the Category Preview Menu.
                      </Tooltip>
                    </h4>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <button
                          id="myTooltip1"
                          style={{
                            width: '5rem',
                            backgroundColor: 'transparent',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '20px',
                            border: '1px solid white',
                            textAlign: 'center',
                            marginRight: '10px',
                          }}
                        >
                          {t('pay-now')}
                        </button>
                        <Tooltip
                          target="myTooltip1"
                          isOpen={this.state.payToolTipOpen}
                          toggle={this.togglePayTooltip}
                        >
                          This is the "Pay Now" button. It will initiate the
                          payment process.
                        </Tooltip>

                        <button
                          id="myTooltip2"
                          style={{
                            width: '7rem',
                            backgroundColor: 'transparent',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '20px',
                            border: '1px solid white',
                            textAlign: 'center',
                          }}
                        >
                          {t('call-waiter')}
                        </button>
                        <Tooltip
                          target="myTooltip2"
                          isOpen={this.state.callWaiterToolTipOpen}
                          toggle={this.toggleCallWaiterTooltip}
                        >
                          This is the "Call Waiter" button. It will call the
                          waiter for assistance.
                        </Tooltip>
                      </div>

                      <FontAwesomeIcon
                        id="myTooltip3"
                        style={{
                          width: '45px',
                          height: '30px',
                          marginLeft: '10px',
                          background: 'green',
                          color: 'white',
                          borderRadius: '5px',
                          padding: '5px',
                          cursor: 'pointer',
                        }}
                        icon={faCartPlus}
                        className="mr-1"
                      />

                      <Tooltip
                        target="myTooltip3"
                        isOpen={this.state.cartToolTipOpen}
                        toggle={this.toggleCartToolTip}
                      >
                        This is your shopping cart.
                      </Tooltip>
                    </div>
                  </CardHeader>
                  <div className="mt-1 p-2">
                    <Slider
                      dots={false} // You can customize settings as needed
                      infinite={false}
                      speed={500}
                      arrows={false}
                      slidesToShow={3} // Number of slides to show at a time
                      slidesToScroll={1}
                    >
                      {menuData.map((menu, index) => (
                        <div key={index}>
                          <button
                            className="btn btn-sm "
                            style={{
                              // width: '14rem',
                              borderRadius: '10px',
                              width: '10rem',
                              fontSize: '10px',
                              backgroundColor:
                                menu.name === categoryItem?.name
                                  ? branding?.basecolor
                                  : 'white',
                              color:
                                menu.name === categoryItem?.name
                                  ? 'white'
                                  : 'green',
                            }}
                          >
                            {menu.name}
                          </button>
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div>
                    <h1
                      style={{
                        fontSize: '14px',
                        color: 'grey',
                        margin: '4px 30px',
                      }}
                    >
                      {dishtags.map((tag, index) => (
                        <span key={index}>#{tag.tag_name}&nbsp;</span>
                      ))}
                    </h1>
                  </div>

                  <div
                    className="mt-2"
                    style={{ fontWeight: 'bolder', fontSize: '17px' }}
                  >
                    {categoryItem?.name}
                  </div>

                  <div className="row mt-1">
                    {categoryItem?.Menus.slice(0, 7).length > 0 ? (
                      categoryItem?.Menus.slice(0, 7).map((menu, index) =>
                        menu.menu_status === 1 ? (
                          <div
                            className="col-6"
                            key={index}
                            style={{
                              marginBottom: '15px',
                              position: 'relative',
                            }}
                          >
                            <Card style={imageContainer}>
                              <div
                                style={{
                                  position: 'relative',
                                  height: '100%',
                                  width: '100%',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  this.props.history.push(
                                    '/menu/menu-option-preview',
                                    {
                                      category_data: categoryItem,
                                      menu_data: menu,
                                    }
                                  )
                                }
                              >
                                <img
                                  src={`${CONSTANTS.API_URL}/restaurant_data/${menu.menu_photo}`}
                                  alt={menu.name}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = `${CONSTANTS.API_URL}/restaurant_data/${branding.logo}`;
                                  }}
                                  style={{
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '10px',
                                  }}
                                />
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    borderRadius: '10px',
                                    height: '100%',
                                    background: 'rgba(0, 0, 0, 0.25)',
                                  }}
                                ></div>
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    color: 'white',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {menu.menu_name}
                                </div>
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '10px',
                                    color: 'white',
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </div>
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    right: '10px',
                                    width: '5rem',
                                    backgroundColor: 'white',
                                    color: 'green',
                                    padding: '5px',
                                    borderRadius: '20px',
                                    border: '1px solid black',
                                    textAlign: 'center',
                                  }}
                                >
                                  {location.Currency.currency_symbol + menu.menu_price}
                                </div>
                              </div>
                            </Card>
                          </div>
                        ) : null
                      )
                    ) : (
                      <div>No items found</div>
                    )}
                  </div>
                </Card>
                <button
                  style={{
                    background: 'transparent',
                    border: '2px solid white',
                    borderRadius: '50%',
                    width: '43px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '20px',
                    left: '48%',
                    color: 'white',
                  }}
                >
                  <FontAwesomeIcon icon={faCircleNotch} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    locations: {
      location: { data: locationData, loading: locationLoading },
    },
    menu: {
      allDishTags: { data: dishtags },
    },
    categories: {
      categoryMenu: { data: menuData },
    },
  } = state;
  return {
    location: locationData,
    loading: locationLoading,
    dishtags,
    menuData,
  };
}

export default withTranslation('global')(
  connect(mapStateToProps, {
    getLocation,
    getAllDishTags,
    getMenuGroupedByCategory,
  })(MenuMobilePreview)
);
