import { serialize } from "php-serialize";
import React, { Component } from "react";
import { connect } from "react-redux";
import CONSTANTS from "../../constants";
import ParamHelper from "../../helpers/ParamHelper";
import { getLocation, updateLocation } from "../../redux/actions/locations";
import LocationService from "../../services/LocationService";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import ErrorHandler from "../shared/ErrorHandler";
import LocationSelectHeader from "../shared/LocationSelectHeader";
import SelectLocation from "../shared/SelectLocation";
import LocationForm from "./LocationForm";


import i18n from "i18next";
import { withTranslation } from "react-i18next";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      selectLocation: false,
    };
  }

  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    await this.props.getLocation(ParamHelper.getLocationId(this.props));
    if (this.props.location) {
      this.setState({
        loading: false,
        location: JSON.parse(JSON.stringify(this.props.location)),
        isOutlet: this.props.location.is_outlet,
      });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id) {
    let location;
    if (
        loc_id !== 0 &&
        (!this.state.location || loc_id !== this.state.location.loc_id)
    ) {
      await this.props.getLocation(loc_id);
      location = this.props.location;
    } else {
      location = this.state.location;
    }
    this.setState({
      loading: false,
      location: JSON.parse(JSON.stringify(location)),
    });
  }

  async toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      await this.setState({ activeTab: tab });
    }
  }

  async updateAreas(options) {
    const location = { ...this.props.location, options: serialize(options) };
    const formData = new FormData();
    formData.append("locationItem", JSON.stringify(location));
    formData.loc_id = this.props.location.loc_id;
    await this.props.updateLocation(formData);
  }

  toggleLocation() {
    this.setState({ selectLocation: !this.state.selectLocation });
  }

  onBlur() {
    if (this.state.location)
      this.setState({ selectLocation: !this.state.selectLocation });
  }

  async onSelect(location) {
    this.setState({ loading: true });
    await LocationService.setLocation(location);
    await this.refreshData(location.loc_id);
    this.setState({ selectLocation: false, loading: false });
    this.props.history.push(
        `${CONSTANTS.BASE_URL}locations/${location.loc_id}`
    );
  }

  render() {
    
    const { location, loading } = this.props;
    const {
      state: { selectLocation },
    } = this;

    // TODO: Implement proper error handling
    if (location && location.loc_id) {
      const locationName = location ? location.name : "";

      if (selectLocation)
        return (
            <SelectLocation
                onBack={this.toggleLocation.bind(this)}
                onSelect={this.onSelect.bind(this)}
            />
        );

      return (
          <div className="location">
            <LocationSelectHeader
                className="mb-1"
                text={locationName}
                onIconClick={this.toggleLocation.bind(this)}
            />
            {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.toggleTab("1");
                    }}
                  >
                    {t('basic')} </NavLink>
                </NavItem> */}
            {/* <NavItem>
									<NavLink
										className={classnames({
											active: this.state.activeTab === "2"
										})}
										onClick={() => {
											this.toggleTab("2")
										}}
									>
										Order Types
									</NavLink>
								</NavItem> */}
            <LocationForm />
          </div>
      );
    }
    if (loading) return <SpinnerComponent />;
    return <ErrorHandler />;
  }
}

function mapStateToProps(state) {
  const { location } = state.locations;
  return { location: location.data, loading: location.loading };
}

export default withTranslation("global")(
    connect(mapStateToProps, { getLocation, updateLocation })(Location)
);
