import { DeleteForever } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box, Button,
    Grid,
    IconButton,
    Modal,
    Stack,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { addCustomerModalStyles, itemModal } from "../../../../assets/posStyles";
import UserService from "../../../../services/UserService";
import GenericMaps from "./genericMaps";
import LocationAutoComplete from "./locationAutoComplete";

const customerFields = [
    {
        label: 'name',
        name: 'Name',
        type: 'text',
        placeholder: 'Please, enter name'
    },

    {
        label: 'email',
        name: 'Email',
        type: 'text',
        placeholder: 'Please, enter email'
    },

    {
        label: 'number',
        name: 'Phone',
        type: 'text',
        placeholder: 'Please, enter phone number'
    },

    {
        label: 'address',
        name: 'Address',
        type: 'multiline',
        placeholder: 'Please, enter address'
    }
]

const AddCustomerModal = ({openModal, setOpenModal, setSnackbarParams}) => {
    const userLocationData = useSelector(state => state.pos.userLocationData);
    const {t} = useTranslation('global');
    const [fieldsData, setFieldsData] = useState(
        {
            name: '',
            email: '',
            number: '',
            address: '',
        }
    )

    // const [customerType, setCustomerType] = useState('new');
    useEffect(() => {
        setFieldsData({...fieldsData, address: userLocationData.address});
    }, [userLocationData.address]);

    const handleModalClose = () => {
        setOpenModal(false);
    }

    // const handleCustomerTypeChange = (event) => {
    //     setCustomerType(event.target.value)
    // }

    const handleChangeCustomerDetail = (e, fieldName) => {
        setFieldsData({...fieldsData, [fieldName]: e.target.value});
    }

    const saveCustomerDetail = async () => {
        if (!fieldsData.email || fieldsData.email === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: "Please, add customer email"
            })
            return;
        }

        if (!fieldsData.number || fieldsData.number === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: "Please, add customer number"
            })
            return;
        }

        if (!userLocationData.lat || userLocationData.lat === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: "Please, select a user address"
            })
        }

        if (!userLocationData.long || userLocationData.long === '') {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: "Please, select a user address"
            })
        }

        const response = await UserService.addPOSUser({
            ...fieldsData,
            latitude: userLocationData.lat,
            longitude: userLocationData.long
        });
        if (response.error) {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: response.message,
            })
        } else {
            setSnackbarParams({
                open: true,
                severity: 'success',
                message: response.message
            })
            setOpenModal(false);
        }
    }

    return (
        <>
            <Modal open={openModal} onClose={()=> setOpenModal(false)} sx={itemModal.modalStyle}>
                <Stack sx={addCustomerModalStyles.mainStack}>
                    <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='h6' sx={{fontSize: '1rem', fontWeight: '600'}}>Add Customer</Typography>
                        <IconButton size='small' onClick={()=> handleModalClose()}>
                            <CloseIcon sx={{height: '20px', width: '20px', color: 'black'}}/>
                        </IconButton>
                    </Stack>
                    <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />
                    <Stack sx={{rowGap: '0.5rem', padding: '1rem', overflowY: 'auto', maxHeight: '80vh'}}>
                        {/*<Stack>*/}
                        {/*    <Typography variant='body2'>Customer Type</Typography>*/}
                        {/*    <RadioGroup*/}
                        {/*        row*/}
                        {/*        value={customerType}*/}
                        {/*        onChange={handleCustomerTypeChange}*/}
                        {/*    >*/}
                        {/*        <FormControlLabel value="new" control={<Radio />} label="New" />*/}
                        {/*        <FormControlLabel value="existing" control={<Radio />} label="Existing" />*/}
                        {/*    </RadioGroup>*/}
                        {/*</Stack>*/}
                        <Grid container rowSpacing={1} columnSpacing={2}>
                            {customerFields.map((item, index) => (
                                <Grid key={index} item xs={12} md={index === 3 ? 12 : 6}>
                                    <Stack>
                                        <Typography variant='body2'>{item.name}</Typography>
                                        <TextField
                                            value={fieldsData[item.label]}
                                            rows={index === 3 ? 4 : 1}
                                            multiline={index === 3}
                                            fullWidth
                                            variant='outlined'
                                            size='small'
                                            placeholder={item.placeholder}
                                            onChange={(e)=> handleChangeCustomerDetail(e, item.label)}
                                        />
                                    </Stack>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <Typography variant='body2'>Select Location</Typography>
                                <LocationAutoComplete />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='body2'>Pin Location</Typography>
                                <GenericMaps />
                            </Grid>
                        </Grid>
                    </Stack>
                    <Box sx={{height: '1px', width: '100%', backgroundColor: grey[200]}} />
                    <Stack direction='row' sx={{padding: '0.5rem 1rem', alignItems: 'center', columnGap: '1rem'}}>
                        <Button variant='contained' onClick={()=> saveCustomerDetail()} sx={{backgroundColor: green[600]}}>Save</Button>
                        <Button variant='contained' onClick={()=> setOpenModal(false)} sx={{backgroundColor: 'black'}}>Cancel</Button>
                        <Tooltip title={'Clear Customer Details'}>
                            <IconButton size='small' sx={{marginLeft: 'auto'}}>
                                <DeleteForever />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            </Modal>
        </>
    )
}

export default AddCustomerModal;