import classnames from 'classnames';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Navbar } from 'reactstrap';
import CONSTANTS from '../../../constants';
import socketService from "../../../helpers/socket";
import { getAllNavBarNotifications } from "../../../redux/actions/notifications";
import LocationService from "../../../services/LocationService";
import LoginService from '../../../services/LoginService';
import NavbarBookmarks from './NavbarBookmarks';
import NavbarUser from './NavbarUser';

const UserName = () => {
    let authData = LoginService.getLoggedInAuthData();
    let username = authData?.staff_name ? authData.staff_name : 'Manager';
    return username;
};
const userEmail = () => {
    let authData = LoginService.getLoggedInAuthData();
    let user_mail = authData?.email ? authData.email : 'abc@gmail.com';
    return user_mail;
};
const signupDate = () => {
    let authData = LoginService.getLoggedInAuthData();
    let date = authData?.createdAt ? authData.createdAt : '';
    return date;
};
const firstName = () => {
    let authData = LoginService.getLoggedInAuthData();
    let fName = authData?.first_name ? authData.first_name : '';
    return fName;
};
const lastName = () => {
    let authData = LoginService.getLoggedInAuthData();
    let lName = authData?.last_name ? authData.last_name : '';
    return lName;
};
const UserRole = () => {
    let userrole = LoginService.getRole();
    return userrole.role;
};



const ThemeNavbar = (props) => {
    const dispatch = useDispatch();
    const { navigationConfig } = props;
    const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark'];
    const navbarTypes = ['floating', 'static', 'sticky', 'hidden'];
    // const userInfo = {
    //     username: UserName(), // REQUIRED - any unique user identifier
    //     email: userEmail(),
    //     firstname: firstName(),
    //     lastname: lastName(),
    //     signUpAt: signupDate(),
    //     role: UserRole(),
    //     props: { customProp1: '123' },
    // };
    const locationData = useSelector(state => state.locations.location);

    console.log(locationData);

    useEffect(() => {
        const loc = LocationService.getLocation();
        try {
            dispatch(getAllNavBarNotifications(loc.loc_id));
        } catch (err) {
            console.log("Error", err);
        }

        const role = LoginService.getRole();

        if (role.role === 'Restaurant Manager') {
            socketService.on("Call_Waiter", async (data) => {
                if (data) {
                    // audio.pause();
                    // audio.currentTime = 0;
                    // audio.play();
                    await dispatch(getAllNavBarNotifications(loc.loc_id));
                }
            });
        }
    }, [locationData?.data]);

    return (
        <React.Fragment>
            {/* <ProductFruits
        workspaceCode="j3pE3bEdkvpuSuFQ"
        language="en"
        user={userInfo}
      /> */}
            <div className="content-overlay" />
            <div className="header-navbar-shadow" />
            <Navbar
                className={classnames(
                    'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
                    {
                        'navbar-light':
                            props.navbarColor === 'default' ||
                            !colorsArr.includes(props.navbarColor),
                        'navbar-dark': colorsArr.includes(props.navbarColor),
                        'bg-primary':
                            props.navbarColor === 'primary' && props.navbarType !== 'static',
                        'bg-danger':
                            props.navbarColor === 'danger' && props.navbarType !== 'static',
                        'bg-success':
                            props.navbarColor === 'success' && props.navbarType !== 'static',
                        'bg-info':
                            props.navbarColor === 'info' && props.navbarType !== 'static',
                        'bg-warning':
                            props.navbarColor === 'warning' && props.navbarType !== 'static',
                        'bg-dark':
                            props.navbarColor === 'dark' && props.navbarType !== 'static',
                        'd-none': props.navbarType === 'hidden' && !props.horizontal,
                        'floating-nav':
                            (props.navbarType === 'floating' && !props.horizontal) ||
                            (!navbarTypes.includes(props.navbarType) && !props.horizontal),
                        'navbar-static-top':
                            props.navbarType === 'static' && !props.horizontal,
                        'fixed-top': props.navbarType === 'sticky' || props.horizontal,
                        scrolling: props.horizontal && props.scrolling,
                    }
                )}
            >
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div
                            className="navbar-collapse d-flex justify-content-between align-items-center"
                            id="navbar-mobile"
                        >
                            <div className="bookmark-wrapper">
                                <NavbarBookmarks
                                    sidebarVisibility={props.sidebarVisibility}
                                    handleAppOverlay={props.handleAppOverlay}
                                    navigationConfig={navigationConfig}
                                />
                            </div>
                            {props.horizontal ? (
                                <div className="logo d-flex align-items-center">
                                    <div className="brand-logo mr-50"></div>
                                    <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                                </div>
                            ) : null}
                            <NavbarUser
                                handleAppOverlay={props.handleAppOverlay}
                                changeCurrentLang={props.changeCurrentLang}
                                userName={<UserName userdata={[]} {...props} />}
                                userRole={<UserRole />}
                                userImg={''}
                                loggedInWith={
                                    props.user !== undefined &&
                                    props.user.login.values !== undefined
                                        ? props.user.login.values.loggedInWith
                                        : null
                                }
                                logoutWithJWT={props.logoutWithJWT}
                                logoutWithFirebase={props.logoutWithFirebase}
                                navBarNotifications={props.notifications}
                                changeMode={props.changeMode}
                                sessionDetail={(session_id, notification_id) =>
                                    (window.location.href = `${CONSTANTS.BASE_URL}sessions/${session_id}?n=${notification_id}`)
                                }
                            />
                        </div>
                    </div>
                </div>
            </Navbar>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const {
        notifications: {
            allnotifications: {
                data: notificationData,
                loading: notificationLoading,
            },
        },
    } = state;

    return {
        notifications: notificationData,
        loading: notificationLoading,
    };
};

export default connect(mapStateToProps)(ThemeNavbar);
