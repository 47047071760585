// reducer.js
import CONSTANTS from "../../../constants";
import { initialState } from "./initialState";

export const cartMenu = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ACTIONS.CART.ADD_ITEMS_TO_CART:
      return { ...state, cart: action.payload };

    case CONSTANTS.ACTIONS.CART.UPDATE_ITEMS_IN_CART:
      return { ...state, cart: [...action.payload, ...state.cart] };

    case CONSTANTS.ACTIONS.CART.GET_CART_TOTAL: {
      console.log("STATE.TOTALAMOUNT", state.totalamount);
      console.log("ACTION.PAYLOAD.TOTALAMOUNT", action.payload.totalAmount);
      const totalamount =
          parseFloat(state.totalamount) + parseFloat(action.payload.totalAmount);
      const subtotal =
          parseFloat(state.subtotal) + parseFloat(action.payload.subTotal);
      const totaltax =
          parseFloat(state.totaltax) + parseFloat(action.payload.totalTax);
      return {
        ...state,
        totalamount,
        subtotal,
        totaltax,
      };
    }
    case CONSTANTS.ACTIONS.CART.GET_ALL_AMOUNTS: {
      return {
        ...state,
        totalamount: parseFloat(action.payload.totalAmount),
        subtotal: parseFloat(action.payload.subTotal),
        totaltax: parseFloat(action.payload.totalTax),
      };
    }

    case CONSTANTS.ACTIONS.CART.CLEAR_CART:
      return {
        ...state,
        cart: [],
        totalQuantity: 0,
        totalamount: 0,
        subtotal: 0,
        totaltax: 0,
        loc_id: null,
        user_id: null,
        table_no: null,
        total_items: null,
        order_time: null,
        order_date: null,
        order_type_id: null,
        order_variant: "small",
        discount_id: 0,
        total_discount: 0,
        totalPrice: 0,
        session_date: null,
        start_time: null,
        comment: "",
        end_time: null,
        qrcode: null,
        qrcodedata: "",
        promocode_id: null,
        payment_status_id: null,
        order_tax: null,
      };

    case CONSTANTS.ACTIONS.CART.REMOVE_ITEM: {
      let subtotal = 0;
      let totalamount = 0;
      let totaltax = 0;
      let menutax = 0;

      if (action.payload.items.length > 0) {
        action.payload.items.forEach((item) => {
          let currentItemBasePrice = 0;
          if (item.MenuOptions.length > 0) {
            item.MenuOptions.forEach((menuOptions) => {
              menuOptions.Option_Values.forEach((option) => {
                if (menuOptions.base_price === 1) {
                  currentItemBasePrice = 1;
                  subtotal += parseFloat(option.price) * item.itemCount;
                  totalamount += parseFloat(option.price) * item.itemCount;
                  if (option.order_item_tax > 0) {
                    totaltax +=
                        parseFloat(option.order_item_tax) * option.optionCount;
                    totalamount +=
                        parseFloat(option.order_item_tax) * item.itemCount;
                  }
                } else {
                  subtotal += parseFloat(option.price) * option.optionCount;
                  totalamount += parseFloat(option.price) * option.optionCount;
                  if (option.order_item_tax > 0) {
                    totaltax +=
                        parseFloat(option.order_item_tax) * option.optionCount;
                    totalamount +=
                        parseFloat(option.order_item_tax) * option.optionCount;
                  }
                }
              });
            });
          }

          if (currentItemBasePrice === 0) {
            subtotal += parseFloat(item.menu_price) * item.itemCount;
            totalamount += parseFloat(item.menu_price) * item.itemCount;
            menutax =
                (item.menu_price / 100) *
                item.menu_tax_percentage *
                item.itemCount;
            menutax = parseFloat(menutax).toFixed(2);
            totalamount += parseFloat(menutax);
          }
          totaltax += parseFloat(menutax);
        });

        if (action.payload.items.length === 0) {
          subtotal = totaltax = totalamount = 0;
        }
      }

      return {
        ...state,
        totalamount,
        subtotal,
        totaltax,
        cart: action.payload.items,
      };
    }

    default:
      return state;
  }
};
