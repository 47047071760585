import _ from "lodash";
import React from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Check, Edit2 } from "react-feather";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Checkbox from "../@vuexy/checkbox/CheckboxesVuexy";

import { useTranslation } from "react-i18next";

const ListStaff = (props) => {
  console.log("PROPS",props.staff)

  const [t] = useTranslation("global");

  const editStaff = (id) => {
    props.editStaff(id);
  };

  const assignRole = (id) => {
    props.assignRole(id);
  };

  let columns = [];
  props?.viewDetailbtn
    ? (columns = [
        {
          name: t("edit"),
          selector: (row) => (row.staff_id),
          format: (val) => {
            return (
              <Button
                outline
                className="btn-icon"
                color="primary"
                onClick={() => editStaff(val.staff_id)}
              >
                <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
              </Button>
            );
          },
        },

        {
          name: t("name"),
          selector: (row) => (row.staff_name),
          minWidth: "200px",
          format: (val) => {
            return val.staff_name ? (
              <Link to={`${"locations/all/" + val.staff_id}`}>
                {val.staff_name}
              </Link>
            ) : (
              t("not-available")
            );
          },
        },
        {
          name: t("email"),
          selector: (row) => (row.staff_email),
          minWidth: "300px",
          format: (val) => {
            return val.staff_email ? val.staff_email : t("not-available");
          },
        },
        {
          name: t("roles"),
          selector: (row) => (row.StaffLocations),
          sortable: true,
          format: (val) => {
            return val.StaffLocations ? val.StaffLocations.length : 0;
          },
        },
        {
          name: t("role-level"),
          selector: (row) => (row.StaffLocations),
          minWidth: "200px",
          format: (val) => {
            return val.StaffLocations && val.StaffLocations.length > 0
              ? _.chain(val.StaffLocations)
                  .groupBy("StaffGroup.staff_group_name")
                  .map((value, key) => ({ groups: key, stafflocations: value }))
                  .value()
                  .map((row) => {
                    return row.groups && row.groups !== "undefined" ? (
                      <>
                        <div className="row">
                          <div className="col-lg-12">
                            <a href="#">{row.groups}</a>
                          </div>
                        </div>
                      </>
                    ) : (
                      t("no-role")
                    );
                  })
              : t("no-role");
          },
        },
        {
          name: t("date-added"),
          selector: (row) => (row.date_added),
          minWidth: "200px",
          sortable: true,
          format: (val) => {
            return val.date_added ? val.date_added : t("not-available-0");
          },
        },
      ])
    : (columns = [
        {
          name: "ID",
          selector: (row) => (row.staff_id),
          sortable: true,
        },
        {
          name: t("edit"),
          selector: (row) => (row.staff_id),
          format: (val) => {
            return (
              <Button
                outline
                className="btn-icon"
                color="primary"
                onClick={() => editStaff(val.staff_id)}
              >
                <Edit2 color="var(--primary)" className="ml-0 p-0" size={10} />
              </Button>
            );
          },
        },
        {
          name: t("assign-role"),
          selector: (row) => (row.staff_id),
          minWidth: "150px",
          format: (val) => {
            return (
              <Button
                outline
                className="btn-icon"
                color="primary"
                onClick={() => assignRole(val.staff_id)}
              >
                {t("assign-role")}
              </Button>
            );
          },
        },
        {
          name: t("name"),
          selector: (row) => (row.staff_name),
          minWidth: "200px",
          format: (val) => {
            return val.staff_name ? val.staff_name : t("not-available");
          },
        },
        {
          name: t("email"),
          selector: (row) => (row.staff_email),
          minWidth: "300px",
          format: (val) => {
            return val.staff_email ? val.staff_email : t("not-available");
          },
        },
        {
          name: t("roles"),
          selector: (row) => (row.StaffLocations),
          sortable: true,
          format: (val) => {
            return val.StaffLocations ? val.StaffLocations.length : 0;
          },
        },
        {
          name: t("role-level"),
          selector: (row) => (row.StaffLocations),
          minWidth: "200px",
          format: (val) => {
            return val.StaffLocations && val.StaffLocations.length > 0
              ? _.chain(val.StaffLocations)
                  .groupBy("StaffGroup.staff_group_name")
                  .map((value, key) => ({ groups: key, stafflocations: value }))
                  .value()
                  .map((row,key) => {
                    return row.groups && row.groups !== "undefined" ? (
                      <>
                        <div key={key} className="row">
                          <div className="col-lg-12">
                            <a href="#">{row.groups}</a>
                          </div>
                        </div>
                      </>
                    ) : (
                      t("no-role")
                    );
                  })
              : t("no-role");
          },
        },
        {
          name: t("date-added"),
          selector: (row) => (row.date_added),
          minWidth: "200px",
          sortable: true,
          format: (val) => {
            return val.date_added ? val.date_added : t("not-available-0");
          },
        },
      ]);

  const renderStaff = (staff, handleChange) => {
    if (!staff || staff.length === 0)
      return <h1>{t("no-app-staff-user-found")}</h1>;

    const tableData = {
      columns: columns,
      data: staff,
      print: false,
      export: false,
    };
    return (
      <DataTableExtensions exportHeaders {...tableData}>
        <DataTable
          noHeader
          pagination
          paginationDefaultPage={1}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationTotalRows={staff.length}
          selectableRows={props.viewDetailbtn ? false : true}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={{
            color: "primary",
            icon: <Check className="vx-icon" size={12} />,
            label: "",
            size: "sm",
          }}
          onSelectedRowsChange={handleChange}
        />
      </DataTableExtensions>
    );
  };
  return renderStaff(props.staff, props.handleChange);
};

export default ListStaff;
